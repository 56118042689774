import React from 'react';

import CallToAction from '../../../molecules/CallToAction/CallToAction';

import useWindowSize from '../../../hooks/useWindowSize';

import { Container } from '../styles/Text.styled';

const Text = ({ theme, heading, subhead, link, align }) => {
  const { width } = useWindowSize();
  return (
    <Container align={align || 'center'} theme={theme}>
      {heading && <p className="heading">{heading}</p>}
      {subhead && (
        <div
          className="subhead"
          dangerouslySetInnerHTML={{ __html: subhead }}
        />
      )}
      {link?.url && (
        <CallToAction
          variant={link?.variant}
          value={link?.label}
          link={link?.url}
          popupReference={link?.popupReference}
          size={width > 576 ? link?.size : 'md'}
          icon={link?.icon}
          scrollId={link?.scrollId}
          reverse={link?.reverse}
          className="popup-btn"
        />
      )}
    </Container>
  );
};
export default Text;

import React from 'react';

import Text from '../components/Text';
import CloseBtn from '../components/CloseBtn';
import OptimizedImage from '../../../utils/OptimizedImage';

import {
  Container,
  Wrapper,
  Image,
  Details,
} from '../styles/ImagePopup.styled';

const ImagePopup = ({ component, closePopup }) => {
  const {
    theme,
    heading,
    subhead,
    link,
    ref: {
      image: { gatsbyImageData, alt, url, title },
    },
  } = component;

  return (
    <Container>
      <Wrapper theme={theme}>
        <Details>
          <CloseBtn theme={theme} closePopup={closePopup} />
          <Text
            theme={theme}
            heading={heading}
            subhead={subhead}
            link={link}
            align="left"
          />
        </Details>
        <Image>
          <OptimizedImage
            src={url}
            image={gatsbyImageData}
            alt={alt}
            title={title}
          />
        </Image>
      </Wrapper>
    </Container>
  );
};

export default ImagePopup;

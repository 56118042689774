import styled from 'styled-components';
import { font } from '../../../styles/atoms/typography';
import { colors } from '../../../styles/atoms/colors';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const textColorMap = {
  blue: colors.base.white,
  light: colors.base.black,
  dark: colors.base.light,
};
export const Container = styled.div`
    width: 100%;
    max-width: 645px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin: 0px auto;
    ${atMinWidth.xl`
      align-items: ${({ align }) =>
        align === 'center' ? 'center' : 'flex-start'};
      max-width: 475px;
    `}
    .heading {
      ${font('display', 'lg', '700')}
      color: ${props => textColorMap[props.theme]};
      text-align: center;
      ${atMinWidth.lg`
        ${font('display', 'xl', '700')}
      `}
      ${atMinWidth.xl`
        text-align: ${({ align }) => align};
      `}
    }
    .subhead, .subhead p {
      ${font('text', 'md', '400')}
      color: ${props => textColorMap[props.theme]};
      text-align: center;
      ${atMinWidth.md`
        ${font('text', 'lg', '400')}
      `}
      ${atMinWidth.lg`
        ${font('display', 'sm', '400')}
      `}
      ${atMinWidth.xl`
        text-align: ${({ align }) => align};
      `}
    }
    .subhead + div {
      width: 100%;
      ${atMinWidth.md`
        width: fit-content;
      `}
    }
    && .popup-btn {
      width: 100%;
      ${atMinWidth.md`
        width: fit-content;
      `}
    }
`;

import styled from 'styled-components';
import { colors } from '../../../styles/atoms/colors';

export const iconColorMap = {
  blue: colors.base.white,
  light: colors.base.black,
  dark: colors.base.light,
};
export const Container = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const Button = styled.button`
  width: 25px;
  height: 25px;
  border: none;
  outline: none;
  background-color: transparent;
`;

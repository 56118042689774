import React from 'react';

import Icon from '../../../styles/atoms/icons';
import { colors } from '../../../styles/atoms/colors';
import { Container, Button, iconColorMap } from '../styles/CloseBtn.styled';

const CloseBtn = ({ theme, fillColor, closePopup }) => (
  <Container className="close-btn">
    <Button type="button" onClick={() => closePopup()}>
      <Icon
        id="xmark-regular"
        size="25"
        fill={fillColor || iconColorMap[theme] || colors.base.white}
      />
    </Button>
  </Container>
);

export default CloseBtn;
